/* eslint-disable react/forbid-prop-types */
import {
  Banner,
  Button,
  Modal,
  SageClassnames,
  Tooltip,
  useSageBreakpoint,
} from '@kajabi/sage-react';
import { useElements } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CustomFields from 'apps/commerce/common/checkout/components/CustomFields/CustomFields';
import PayButton from 'apps/commerce/common/checkout/components/PayButton';
import SecureCheckout from 'apps/commerce/common/checkout/components/SecureCheckout';
import ServiceAgreement from 'apps/commerce/common/checkout/components/ServiceAgreement';
import useCheckoutSettings from 'apps/commerce/common/checkout/hooks/useCheckoutSettings';
import AddressInput from 'apps/commerce/common/checkout/inputs/AddressInput';
import EmailInput from 'apps/commerce/common/checkout/inputs/EmailInput';
import NameInput from 'apps/commerce/common/checkout/inputs/NameInput';
import OptInCheckbox from 'apps/commerce/common/checkout/inputs/OptInCheckbox';
import PaymentDetails from 'apps/commerce/common/checkout/inputs/PaymentDetails';
import PhoneInput from 'apps/commerce/common/checkout/inputs/PhoneInput';
import SaveCardCheckbox from 'apps/commerce/common/checkout/inputs/SaveCardCheckbox';
import SavedCard from 'apps/commerce/common/checkout/inputs/SavedCard';
import GiftModal from 'apps/commerce/popup_checkout/GiftModal';
import AbandonmentModal from '../common/checkout/components/AbandonmentModal';
import GiftOffers from './components/GiftOffers';
import { PaymentSummaryText } from './components/PaymentSummaryText';
import { PriceDisplay } from './components/PriceDisplay';
import { PriceSummary } from './components/PriceSummary';
import QuantitySelector from './components/QuantitySelector';
import { CloseIcon } from './images/close';
import PayPalButton from './inputs/PayPalButton';
import PriceOverrideInput from './inputs/PriceOverrideInput';
import TaxIdInput from './inputs/TaxIdInput';
import { localizedDateWithYear, minorUnitAmount } from './util';

function PopupCheckoutModal({
  active,
  displayPrice,
  displayOverridePrice,
  dueNowPriceInCents,
  isLinkInBioPlan,
  isOfferUnavailable,
  showSaveCardCheckbox,
  saveCardDefaultValue,
  isOfferFree,
  submitForm,
  handleHideModal,
  siteUserSignedIn,
  taxLoading,
  isCouponLoading,
  couponError,
  aboveQuantityLimit,
}) {
  const {
    getValues,
    setValue,
    watch,
    formState: { isValid, errors },
  } = useFormContext();
  const { t } = useTranslation();

  const {
    offer,
    price,
    site,
    nameRequired,
    phoneRequired,
    addressRequired,
    collectTaxId,
    priceOverrideRequired,
    member,
    stripe,
    offerPurchase,
  } = getValues();
  const { serviceAgreementRequired, customFields, isCustomFieldsCompleted } = useCheckoutSettings();
  const paymentProvider = watch('paymentProvider');
  const isPaymentDetailComplete = watch('isPaymentDetailComplete');
  const isPaymentInProgress = watch('isPaymentInProgress');
  const priceOverride = watch('priceOverride');
  const useSavedCard = watch('useSavedCard');
  const serviceAgreementChecked = watch('serviceAgreementChecked');
  const giftDetails = watch('giftDetails');
  const giftIsActive = giftDetails && Object.keys(giftDetails).length > 0;

  const [shouldShowGiftOptions, setShouldShowGiftOptions] = React.useState(false);
  const [showAbandonmentModal, setShowAbandonmentModal] = React.useState(false);
  // These conditions need to match the `CheckoutStripeElements` conditions for rendering Stripe's
  // Elements component otherwise this will error.
  if (stripe) {
    // the conditionality of this won't change per render, so we can safely disable the rule
    // eslint-disable-next-line react-hooks/rules-of-hooks
    setValue('elements', useElements());
  }

  const breakpoints = useSageBreakpoint();

  const isLoggedIn = !!member;
  const alreadySubscribed = offerPurchase?.type === 'subscription';
  const shouldDisableForm =
    isOfferUnavailable || !!isPaymentInProgress || alreadySubscribed || aboveQuantityLimit;
  const validOfferPriceOverride =
    minorUnitAmount(priceOverride, price.exponent) >= Math.max(price.amount, 50);
  const isDoingFreePWYW =
    priceOverrideRequired && isOfferFree && (parseFloat(priceOverride) === 0 || !priceOverride);
  const requiresCardDetails =
    !(isOfferFree || isDoingFreePWYW) && stripe && paymentProvider !== 'external_paypal';
  const isReadyToSubmit =
    !shouldDisableForm &&
    isValid &&
    (customFields ? isCustomFieldsCompleted : true) &&
    (!requiresCardDetails || useSavedCard || isPaymentDetailComplete) &&
    (validOfferPriceOverride || !priceOverrideRequired || isDoingFreePWYW) &&
    (!serviceAgreementRequired || serviceAgreementChecked);
  const shouldDisplayPaymentDetails =
    (stripe && paymentProvider === 'external_paypal') ||
    (requiresCardDetails && !isDoingFreePWYW && !useSavedCard);
  const shouldDisplayPreviewTooltip = isReadyToSubmit && siteUserSignedIn;
  const disableSubmit = siteUserSignedIn || !isReadyToSubmit;
  const isRecurring = price.priceType === 'recurring';
  const providerAllowsSavingCard = paymentProvider === 'card';
  const offerPurchaseDate = offerPurchase?.date;
  const isQuantityEnabled = offer.checkoutQuantityEnabled;
  const isOfferGiftable = offer.giftableOffer;

  const customModalClassNames = isOfferGiftable ? 'checkout-modal--gift-offers' : null;

  const handleGiftModalCancel = () => {
    if (
      giftIsActive &&
      (giftDetails.recipientEmail ||
        giftDetails.purchaserName ||
        giftDetails.giftDeliveryDate ||
        giftDetails.message !== t('gift_offers.message'))
    ) {
      // only show the abandonment modal if the user has changed at least 1 field
      setShowAbandonmentModal(true);
    } else {
      setValue('giftDetails', {});
      setShouldShowGiftOptions(false);
    }
  };

  const handleGiftModalContinue = () => {
    setShouldShowGiftOptions(false);
  };

  const handleAbandonmentCancel = () => {
    setShowAbandonmentModal(false);
  };

  const handleAbandonmentConfirm = () => {
    setShowAbandonmentModal(false);
    setShouldShowGiftOptions(false);
    setValue('giftDetails', {});
  };

  const checkoutForm = (
    <>
      <Modal.Header
        className={`${shouldShowGiftOptions ? 'visually-hidden' : 'checkout-modal__header'}`}
        customHeader={<span className="t-sage-body-small-med">{site.title}</span>}
        image={
          site.logoImageUrl && {
            src: site.logoImageUrl,
            alt: '',
          }
        }
        aside={
          <Button
            data-testid="close-modal-button"
            color={Button.COLORS.PRIMARY}
            onClick={handleHideModal}
            subtle={true}
            disabled={isPaymentInProgress}
            className="checkout-modal__close-btn"
          >
            <CloseIcon />
          </Button>
        }
      />
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <form
        onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
        onSubmit={submitForm}
        className={`${shouldShowGiftOptions ? 'visually-hidden' : 'checkout-modal__body'}`}
      >
        <div className="checkout-modal__form">
          <PriceDisplay offer={offer} displayPrice={displayPrice} layoutType="sm" />
          <>
            {priceOverrideRequired && (
              <PriceOverrideInput displayPrice={displayPrice} disabled={shouldDisableForm} />
            )}
          </>
          <Modal.Body>
            <fieldset className="checkout-modal__fieldset">
              <legend className={`checkout-modal__legend ${SageClassnames.TYPE.HEADING_6}`}>
                {t('form.your_information')}
              </legend>
              <EmailInput
                disabled={isLoggedIn || shouldDisableForm}
                defaultValue={isLoggedIn ? member.email : ''}
                label={t('form.email')}
              />
              {nameRequired && <NameInput disabled={shouldDisableForm} />}
              {addressRequired && <AddressInput disabled={shouldDisableForm} />}
              {phoneRequired && <PhoneInput disabled={shouldDisableForm} />}
              {collectTaxId && <TaxIdInput disabled={shouldDisableForm} />}
              <CustomFields disabled={shouldDisableForm} customFields={customFields} />
            </fieldset>
            {!isOfferFree && stripe && (
              <fieldset className="checkout-modal__fieldset">
                <legend className={`checkout-modal__legend ${SageClassnames.TYPE.HEADING_6}`}>
                  {t('form.payment_method')}
                </legend>
                {member?.savedCardDetails && (
                  <SavedCard savedCardDetails={member.savedCardDetails} />
                )}
                {shouldDisplayPaymentDetails && <PaymentDetails disabled={shouldDisableForm} />}
                {requiresCardDetails &&
                  showSaveCardCheckbox &&
                  !useSavedCard &&
                  providerAllowsSavingCard && (
                    <SaveCardCheckbox
                      disabled={shouldDisableForm}
                      saveCardDefaultValue={saveCardDefaultValue}
                    />
                  )}
              </fieldset>
            )}
            <div
              className="checkout-modal__summary checkout-modal__summary--mobile"
              data-display-layout="sm"
            >
              {isQuantityEnabled && <QuantitySelector />}
              {isOfferGiftable && (
                <GiftOffers
                  handleGiftOffer={() => setShouldShowGiftOptions(true)}
                  giftIsActive={giftIsActive}
                />
              )}
              <PriceSummary
                key="price-summary-mobile"
                displayPrice={displayPrice}
                displayOverridePrice={displayOverridePrice}
                dueNowPriceInCents={dueNowPriceInCents}
                taxLoading={taxLoading}
                isCouponLoading={isCouponLoading}
                isLinkInBioPlan={isLinkInBioPlan}
                couponError={couponError}
              />
            </div>
            <div className="checkout-modal__cta">
              <OptInCheckbox disabled={shouldDisableForm} />
              <ServiceAgreement disabled={shouldDisableForm} />
              {shouldDisplayPreviewTooltip && (
                <div className="site-user-tooltip">
                  <Tooltip.Element
                    className="site-user-tooltip"
                    content="Checkout is disabled while you're logged in as a Kajabi site admin"
                  />
                </div>
              )}
              {paymentProvider === 'external_paypal' &&
              !isPaymentInProgress &&
              !alreadySubscribed &&
              !isOfferFree ? (
                <PayPalButton
                  offerToken={offer.token}
                  isRecurring={isRecurring}
                  clientId={offer.paypalClientId}
                  merchantId={offer.paypalMerchantId}
                  onSubmit={submitForm}
                  disable={disableSubmit}
                />
              ) : (
                <PayButton
                  disableSubmit={disableSubmit}
                  isPaymentInProgress={!!isPaymentInProgress}
                  dueNowPriceInCents={dueNowPriceInCents}
                />
              )}
              <PaymentSummaryText />
              {errors.payment && (
                <div className="sage-form-field--error">
                  <div className="sage-input__message">{errors.payment.message}</div>
                </div>
              )}
            </div>
          </Modal.Body>
        </div>
        <div className="checkout-modal__summary" data-display-layout="lg">
          <PriceDisplay offer={offer} displayPrice={displayPrice} layoutType="lg" />
          {isQuantityEnabled && <QuantitySelector />}
          {isOfferGiftable && (
            <GiftOffers
              handleGiftOffer={() => setShouldShowGiftOptions(true)}
              giftIsActive={giftIsActive}
            />
          )}
          <PriceSummary
            key="price-summary"
            displayPrice={displayPrice}
            displayOverridePrice={displayOverridePrice}
            dueNowPriceInCents={dueNowPriceInCents}
            taxLoading={taxLoading}
            isCouponLoading={isCouponLoading}
            isLinkInBioPlan={isLinkInBioPlan}
            couponError={couponError}
          />
        </div>
      </form>
      <Modal.Footer
        className={`${shouldShowGiftOptions ? 'visually-hidden' : 'checkout-modal__footer'}`}
      >
        <SecureCheckout />
      </Modal.Footer>
    </>
  );

  return (
    <>
      <Helmet>
        <html className="checkout-modal__active" lang="en" />
      </Helmet>
      <Modal
        className={`checkout-modal sage-scoped ${customModalClassNames}`}
        size="lg"
        active={active}
        onExit={handleHideModal}
        fullScreen={breakpoints?.sm}
      >
        {offerPurchaseDate && (
          <Banner
            active={true}
            type="secondary"
            text={t('form.already_purchased', {
              offer: offer.title,
              date: localizedDateWithYear(offerPurchaseDate),
            })}
            link={{
              href: '/library',
              name: t('form.take_me_there'),
              rel: 'no-opener',
            }}
          />
        )}
        {shouldShowGiftOptions ? (
          <>
            <GiftModal
              className="gift-modal"
              onClose={handleHideModal}
              onContinue={handleGiftModalContinue}
              onCancel={handleGiftModalCancel}
            />
            {ReactDOM.createPortal(
              <AbandonmentModal
                showModal={showAbandonmentModal}
                handleAbandonmentCancel={handleAbandonmentCancel}
                handleAbandonmentConfirm={handleAbandonmentConfirm}
              />,
              document.body,
            )}
          </>
        ) : null}
        {
          // this is not conditionally rendered (but instead conditionally hidden) because
          // conditionally rendering causes progress to be lost when the gift form is toggled
          checkoutForm
        }
      </Modal>
    </>
  );
}

PopupCheckoutModal.propTypes = {
  active: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
  handleHideModal: PropTypes.func.isRequired,
  displayPrice: PropTypes.string.isRequired,
  displayOverridePrice: PropTypes.string,
  dueNowPriceInCents: PropTypes.number.isRequired,
  isLinkInBioPlan: PropTypes.bool.isRequired,
  isOfferUnavailable: PropTypes.bool.isRequired,
  showSaveCardCheckbox: PropTypes.bool.isRequired,
  saveCardDefaultValue: PropTypes.bool.isRequired,
  isOfferFree: PropTypes.bool.isRequired,
  siteUserSignedIn: PropTypes.bool.isRequired,
  taxLoading: PropTypes.bool,
  isCouponLoading: PropTypes.bool,
  couponError: PropTypes.string,
  aboveQuantityLimit: PropTypes.bool,
};

PopupCheckoutModal.defaultProps = {
  displayOverridePrice: null,
  taxLoading: false,
  isCouponLoading: false,
  couponError: '',
  aboveQuantityLimit: false,
};

export default PopupCheckoutModal;
